.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input:focus {
  outline: none;
}

.react-autosuggest__container--open .react-autosuggest__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  position: absolute;
  top: 51px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion:not(:first-child) {
  border-top: 1px solid #ddd;
}

.react-autosuggest__suggestion--focused {
  background-color: #0c7eaf;
  color: #fff;
}

.suggestion-content {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
}

.dancounsell {
  background-image: url(https://s3.amazonaws.com/uifaces/faces/twitter/dancounsell/48.jpg);
}

.ladylexy {
  background-image: url(https://s3.amazonaws.com/uifaces/faces/twitter/ladylexy/48.jpg);
}

.mtnmissy {
  background-image: url(https://s3.amazonaws.com/uifaces/faces/twitter/mtnmissy/48.jpg);
}

.steveodom {
  background-image: url(https://s3.amazonaws.com/uifaces/faces/twitter/steveodom/48.jpg);
}

.name {
  margin-left: 68px;
  line-height: 45px;
}

.highlight {
  color: #ee0000;
  font-weight: bold;
}

.react-autosuggest__suggestion--focused .highlight {
  color: #120000;
}
