.select-item {
  clear: both;
  width: 100%;
}
.item-renderer span {
  color: #434c5e !important;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 5px;
}

.dropdown-heading {
  border: 1px solid #d6dae2 !important;
}

.select-panel {
  border: 1px solid #d6dae2 !important;
}

.select-list {
  background-color: #ccc;
}
