.collapse-box {
  width: 100%;
}

.restrictions-box hr {
  margin-top: 5px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 5px;
}

.restrictions-box {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.restrictions-item {
  min-width: 100px;
  margin: 10px;
}

.restrictions-item input {
  margin-right: 5px;
}

.restrictions-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
